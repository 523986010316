import React, { useEffect, useState } from 'react';
import { Button, Input, DatePicker, Col, Modal, Row, Space } from 'antd';
import type { DatePickerProps } from 'antd';
import { PageOptionsModel } from '@app/domain/PageMetaModel';
import { useTranslation } from 'react-i18next';
import { useMounted } from '@app/hooks/useMounted';
import { notificationController } from '@app/controllers/notificationController';
import { getErrorMessage } from '@app/utils/utils';
import { PageTitle } from '../common/PageTitle/PageTitle';
import * as S from '@app/pages/uiComponentsPages//UIComponentsPage.styles';
import {
  cancelScheduledMessage,
  createCustomerMessageBaseRequest,
  createCustomerTestMessage,
  createCustomerTestMessageBaseRequest,
  getScheduledCustomerMessage,
  getScheduledTimeBaseRequest,
  scheduleCustomerMessage,
} from '../../api/firebase.api';
import { createCustomerNotification } from '../../api/firebase.api';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment-timezone';

const initialCreateCustomerMessage: createCustomerMessageBaseRequest = {
  msg: null,
  schedule_time: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
};
const initialTestMessage: createCustomerTestMessageBaseRequest = {
  //   customer_id: 'd199fa90-48e6-11ec-818d-65da82aa2eb2',
  customer_id: '93d05890-5a28-11ed-9524-b3e0b86c9769',
  msg: null,
};
const initialScheduleTime: getScheduledTimeBaseRequest = {
  id: null,
  schedule_time: null,
};

export const FirebaseCustomerInApp: React.FC = () => {
  const [createFirebaseData, setCreateFirebaseData] =
    useState<createCustomerMessageBaseRequest>(initialCreateCustomerMessage);
  const [createFirebaseTestData, setCreateFirebaseTestData] =
    useState<createCustomerTestMessageBaseRequest>(initialTestMessage);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMounted } = useMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledTimeRes, setScheduleTimeRes] = useState<getScheduledTimeBaseRequest[]>([initialScheduleTime]);

  useEffect(() => {
    getSchedulingMsg();
  }, [loading]);

  const getSchedulingMsg = async () => {
    try {
      const response = await getScheduledCustomerMessage();
      setScheduleTimeRes(response);
    } catch (error) {
      if (isMounted) {
        notificationController.error({
          message: `Fetch queue failed`,
          description: getErrorMessage(error),
        });
      }
    }
  };

  const handleOpenModal = () => {
    if (!createFirebaseData.msg) {
      notificationController.error({
        message: 'Create Firebase',
        description: 'Content are required',
      });
      return;
    }
    setIsModalOpen(true);
  };
  const handleCreateFirebase = async () => {
    setLoading(true);
    try {
      const response = await scheduleCustomerMessage(createFirebaseData);
      if (isMounted) {
        notificationController.success({
          message: t('common.success'),
        });
        setCreateFirebaseData(initialCreateCustomerMessage);
      }
    } catch (error) {
      if (isMounted) {
        notificationController.error({
          message: `Create Firebase Failed`,
          description: getErrorMessage(error),
        });
      }
    } finally {
      setIsModalOpen(false);
      setLoading(false);
    }
  };
  const handleCreateTestMessage = async () => {
    setLoading(true);
    try {
      const response = await createCustomerTestMessage(createFirebaseTestData);
      if (isMounted) {
        notificationController.success({
          message: t('common.success'),
        });
      }
    } catch (error) {
      if (isMounted) {
        notificationController.error({
          message: 'send test failed',
          description: getErrorMessage(error),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleContent = (e: any) => {
    setCreateFirebaseData({ ...createFirebaseData, msg: e.target.value });
    setCreateFirebaseTestData({ ...createFirebaseTestData, msg: e.target.value });
  };

  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    const utc = moment(dateString).utc().format('YYYY-MM-DD HH:mm:ss');
    setCreateFirebaseData({ ...createFirebaseData, schedule_time: utc });
  };

  const handleCancel = async (id: number | null) => {
    setLoading(true);
    try {
      if (!!id) {
        await cancelScheduledMessage({ id });
        if (isMounted) {
          notificationController.success({
            message: t('common.success'),
          });
        }
      }
    } catch (error) {
      if (isMounted) {
        notificationController.error({
          message: 'cancel failed',
          description: getErrorMessage(error),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageTitle>{t('common.openIMappMsg')}</PageTitle>
      <Col>
        <S.Card title={t('common.openIMappMsg')}>
          <S.InputsWrapper>
            <TextArea required placeholder={t('App Content')} rows={4} onChange={(e) => handleContent(e)} />
            <Button loading={loading} type="primary" onClick={() => handleCreateTestMessage()}>
              {t('common.test')}
            </Button>
            <DatePicker onChange={onChange} showTime />
            <Button type="primary" onClick={() => handleOpenModal()}>
              {t('common.schedule')}
            </Button>
          </S.InputsWrapper>
        </S.Card>
        <S.Card>
          <Col>
            <Row>
              <Col>{'Queued for Scheduling'}</Col>
            </Row>
            <Col flex={1}>
              <Row justify="space-between" gutter={[32, 0]}>
                <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  {scheduledTimeRes.map((s) => {
                    const { schedule_time, id } = s;
                    return (
                      <div key={id} style={{ display: 'flex', alignItems: 'center' }}>
                        <div>{moment(schedule_time).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss')}</div>
                        <Button loading={loading} danger type="text" onClick={() => handleCancel(id)}>
                          {'Cancel'}
                        </Button>
                      </div>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Col>
        </S.Card>
      </Col>
      <Modal
        title={'Confirm schedule this message?'}
        centered
        open={isModalOpen}
        onOk={() => handleCreateFirebase()}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        confirmLoading={loading}
      ></Modal>
    </>
  );
};
