import { httpApi } from '@app/api/http.api';

export interface createCustomerNotificationBaseRequest {
  title: string;
  body: string | null;
  data_type?: string;
  data_type_value?: string;
}

export interface createCustomerMessageBaseRequest {
  msg: string | null;
  schedule_time: string;
}

export interface createCustomerTestMessageBaseRequest {
  customer_id: string;
  msg: string | null;
}

export interface createCustomerTokensBaseRequest {
  fcm_tokens: string[];
  title: string;
  body: string | null;
  data_type?: string | null;
  data_type_value?: string | null;
}

export interface getScheduledTimeBaseRequest {
  id: number | null;
  schedule_time: string | null;
}

export interface cancelScheduledMsgBaseRequest {
  id: number | null;
}

export const createCustomerNotification = (Payload: createCustomerNotificationBaseRequest): Promise<void> =>
  httpApi
    .post<createCustomerNotificationBaseRequest>(`v1/admin/customer/send-notification-to-all-customer`, Payload)
    .then(({ data }) => {
      console.log(data);
    });

export const createTokensNotification = (Payload: createCustomerTokensBaseRequest): Promise<void> =>
  httpApi.post<createCustomerTokensBaseRequest>(`v1/admin/customer/multiple-notification`, Payload).then(({ data }) => {
    console.log(data);
  });

export const createCustomerTestMessage = (Payload: createCustomerTestMessageBaseRequest): Promise<void> =>
  httpApi.post<createCustomerTestMessageBaseRequest>(`v1/admin/open-im/send-notification`, Payload).then(({ data }) => {
    console.log(data);
  });

export const scheduleCustomerMessage = (Payload: createCustomerMessageBaseRequest): Promise<void> =>
  httpApi.post<createCustomerMessageBaseRequest>(`v1/admin/open-im/send-msg`, Payload).then(({ data }) => {
    console.log(data);
  });

export const getScheduledCustomerMessage = (): Promise<getScheduledTimeBaseRequest[]> =>
  httpApi.get<getScheduledTimeBaseRequest[]>(`v1/admin/open-im/send-msg`).then(({ data }) => {
    console.log(data);
    return data;
  });

export const cancelScheduledMessage = (Payload: cancelScheduledMsgBaseRequest): Promise<void> =>
  httpApi.post<cancelScheduledMsgBaseRequest>(`v1/admin/open-im/cancel-msg`, Payload).then(({ data }) => {
    console.log(data);
  });
